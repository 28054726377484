<template>
  <v-app>
    <v-app-bar app color="primary" dark clipped-left>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          v-if="isAdmin"
          @click.stop="drawer = !drawer"
          dark
        ></v-app-bar-nav-icon>
        <img class="logo-gistda"
          style="padding-left: 12px; cursor: pointer;"
          height="46"
          @click="openWebMarine"
          src="@/assets/img/logo-gistda-marine-white.png"
          alt="logo-gistda"
        />
        <div class="show-text" style="margin-left: 20px">
          <h3 class="title-gisda-survey">GISTDA SURVEY</h3>
          <div>ระบบการสำรวจและนำเข้าข้อมูลภาคสนาม</div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="green" v-if="!$keycloak.authenticated" dark @click="login()">
        เข้าสู่ระบบ
      </v-btn>
      <div v-else class="d-flex justify-center align-center">
        <div dark class="mr-4 white--text show-text">{{ displayName }}</div>
        <v-btn @click="$keycloak.logout()"> ออกจากระบบ </v-btn>
      </div>
    </v-app-bar>
    <v-overlay :value="drawer" z-index="4"></v-overlay>
    <v-navigation-drawer
      v-model="drawer"
      width="auto"
      temporary
      app
      hide-overlay
      v-if="isAdmin"
      :style="{ top: $vuetify.application.top + 'px' }"
    >
      <v-list nav dense>
        <v-list-item-group active-class="light-blue--text text--darken-3">
          <v-list-item class="pa-2" to="/">
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-text</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><h3 class="font-weight-medium">รายการแบบสำรวจ</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pa-2" to="/survey-management">
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-list-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><h3 class="font-weight-medium">ส่วนบริหารจัดการแบบสำรวจ (สำหรับเจ้าหน้าที่)</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pa-2" to="/survey-setting">
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><h3 class="font-weight-medium">ตั้งค่า</h3></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main class="survey-main" style="background-color: #F2F8FD; overflow: hidden;">
        <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    drawer: false,
    items: [
      { title: 'รายการแบบสำรวจ', icon: 'mdi-home' },
      { title: 'จัดการแบบสำรวจ', icon: 'mdi-format-list-numbered' }
    ],
    isAdmin: false,
    displayName: '',
    keycloak_url: process.env.VUE_APP_KEYCLOAK_URL + 'admin/marine/console',
    backgroundColor: '#F2F8FD'
  }),
  mounted () {
    const self = this
    if (self.$keycloak.token) {
      self.$keycloak
        .loadUserProfile()
        .then(function (profile) {
          if ((profile.firstName != null && profile.firstName !== '') && (profile.lastName != null && profile.lastName !== '')) {
            self.displayName = profile.firstName + ' ' + profile.lastName // profile.username
          } else {
            self.displayName = profile.username
          }
        })
        .catch(function (res) {
          console.log(res)
        })
      localStorage.setItem('token', self.$keycloak.token)
      if (self.$keycloak.realmAccess.roles.includes('survey-admin-marine')) {
        self.isAdmin = true
      }
    }
    self.$keycloak.loadUserInfo().success(res => {
      this.$store.state.surveyManagementList.groups = (res.groups.join(',')).replace(/\//g, '')
    })
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }, // test_survey_admin / 123456
    login () {
      window.location.href = this.$keycloak.createLoginUrl()
    },
    openWebMarine () {
      window.open(process.env.VUE_APP_MARINE_URL)
    }
  }
}
</script>

<style scoped lang="scss">
  @media screen and (max-width: 2560px) and (min-width: 750px) {
    .show-text {
      display: inline;
    }
  }
  @media screen and (max-width: 750px) {
    .show-text {
      display: none;
    }
  }
  ::v-deep .survey-main {
    .v-main__wrap {
      margin: 10px 20px;
    }
  }
</style>
