import Vue from 'vue'

const state = {
  mapOnMobile: null,
  loading: false,
  dataGoogleForm: null,
  popupGoogleForm: false
}

const getters = {
}

const actions = {
  async setSurveySpatial ({ state }, result) {
    state.loading = true
    await Vue.axios.post('set/survey_wkt', JSON.stringify(result), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response?.data.success) {
          state.loading = false
          state.popupGoogleForm = true
          state.dataGoogleForm = response.data
        }
      })
      .catch((error) => {
        if (!error?.response.data.success) {
          state.loading = false
          if (error.response.data.message) Vue.$toast.error(error.response.data.message)
        }
      })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
