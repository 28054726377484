import Vue from 'vue'

const state = {
  user_group: [],
  loading: false
}

const getters = {
}

const actions = {
  setKeycloakUserGroup: async ({ dispatch, commit }) => {
    commit('loading', true)
    const result = await Vue.axios?.get('set/keycloak_user_group')
    if (result?.data?.success !== true) return
    await dispatch('getUserGroup')
  },
  getUserGroup: async ({ commit }) => {
    const result = await Vue.axios?.get('get/settings/user_group')
    if (result?.data?.success !== true) return
    commit('loading', false)
    commit('assignUserGroup', result.data.data)
  },
  setUserGroup ({ state }, result) {
    Vue.axios?.post('set/settings/user_group', result, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.data.success) {
          Vue.$toast.success('บันทึกข้อมูลสำเร็จ')
        }
      })
      .catch((error) => {
        if (!error.data.success) {
          Vue.$toast.error('เกิดข้อผิดพลาด โปรดลองอีกครั้ง')
        }
      })
  }
}

const mutations = {
  loading (state, value) {
    state.loading = value
  },
  assignUserGroup (state, value) {
    state.user_group = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
