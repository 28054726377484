import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'survey',
    component: () => import('../views/SurveyLinkView.vue')
  },
  {
    path: '/survey-management',
    name: 'survey_management',
    // component: SurveyLink
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SurveyView.vue'),
    beforeEnter: async (to, from, next) => {
      if (typeof (Vue.$keycloak.token) === 'undefined') {
        return next({ name: 'survey_link' })
      } else {
        next()
      }
    }
  },
  {
    path: '/',
    name: 'survey_link',
    component: () => import('../views/SurveyLinkView.vue')
  },
  {
    path: '/map-coordinates',
    name: 'map_coordinates',
    component: () => import('../views/MapCoordinates.vue')
  },
  {
    path: '/survey-setting',
    name: 'survey_setting',
    component: () => import('../views/SurveySetting.vue'),
    beforeEnter: async (to, from, next) => {
      if (typeof (Vue.$keycloak.token) === 'undefined') {
        return next({ name: 'survey_link' })
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
