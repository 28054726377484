import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import authentication from './plugins/keycloak-plugin'
import './assets/css/global.scss'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { Utils } from './assets/js/utility'

import LongdoMap from 'longdo-map-vue'

Vue.use(LongdoMap, {
  load: {
    apiKey: process.env.VUE_APP_LONGDOMAP_API_KEY
  }
})
Vue.use(authentication)
Vue.config.productionTip = false

Vue.use(Toast, {})
const util = new Utils()
Vue.utils = util

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_DSAS_API

Vue.mixin({
  data () {
    return {
      util
    }
  }
})

Vue.$keycloak
  .init({
    checkLoginIframe: false, onLoad: 'check-sso'
  })
  // .init({ onLoad: 'check-sso' })
  .then(() => {
    new Vue({
      router,
      vuetify,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
