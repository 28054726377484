import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#185787',
        secondary: '#74B8C7',
        success: '#76BA99',
        normal: '#5B5B5B',
        gray: '#A4A4A4',
        error: '#EB4747',
        active: '#2FC3A9',
        inactive: '#D94646',
        border_line: '#E8E6E6',
        hover_border_line: '#CFCFCF',
        blue: '#0B4B5F',
        windowIcon: '#01ADEF'
        // accent: colors.indigo.base, // #3F51B5
        // error: colors.red.accent3,
      }
    }
  }
}

export default new Vuetify(opts)
