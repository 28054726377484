import Vue from 'vue'

const state = {
  survey_list: []
}

const getter = {}

const actions = {
  async getSurveyLinkList ({ commit }, groups) {
    const result = await Vue.axios?.get('get/survey_list?user_group=' + groups + '&is_admin=false')
    if (result?.data?.success !== true) return
    commit('assignSurveyLinkList', result.data.data)
  }
}

const mutations = {
  assignSurveyLinkList (state, value) {
    state.survey_list = value
  }
}

export default {
  namespaced: true,
  state,
  getter,
  actions,
  mutations
}
