import Vue from 'vue'
import Vuex from 'vuex'
import surveyManagementList from '@/store/SurveyManagement/SurveyManagement'
import addSurveyDialog from '@/store/SurveyManagement/AddSurveyDialog'
import userGroup from '@/store/Setting/TabUserGroups'
import surveyList from '@/store/SurveyLink/SurveyLink'
import surveySpatial from '@/store/SurveySpatial/MapCoordinates'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    surveyManagementList,
    userGroup,
    surveyList,
    addSurveyDialog,
    surveySpatial
  }
})
export default store
