import Vue from 'vue'
import { Utils } from '@/assets/js/utility'

const util = new Utils()

const state = {
  survey_spatial: [],
  survey_user_group: [],
  survey_management_list: [],
  survey_edit_list: [],
  message_delete: '',
  loading_delete: false,
  edit_popup_open: false,
  import_popup_open: false,
  spatial_url_popup: {
    open: false,
    description: '',
    title: ''
  },
  delete_popup: {
    open: false,
    id: null,
    text: ''
  },
  groups: ''
}

const getters = {}

const actions = {
  async getRefSurveySpatial ({ commit }) {
    const result = await Vue.axios?.get('get/ref/survey_spatial')
    if (result?.data?.success !== true) return
    commit('assignSurveyValue', result)
  },
  async getRefUserGroup ({ commit }) {
    const result = await Vue.axios?.get('get/ref/user_group')
    if (result?.data?.success !== true) return
    commit('assignSelectUserGroup', result.data.data)
  },
  async getSurveyManagementList ({ state, commit }) {
    const result = await Vue.axios?.get('get/survey_list?user_group=' + state.groups + '&is_admin=true')
    if (result?.data?.success !== true) return
    commit('assignSurveyManagementList', result.data.data)
  },
  async deleteSurveyManagementList ({ commit, dispatch }, id) {
    commit('loadingDelete', true)
    const result = await Vue.axios?.get('delete/survey?id=' + id)
    if (result?.data?.success !== true) commit('assignDeleteMessage', result.data.message)
    dispatch('surveyManagementList/getSurveyManagementList', null, { root: true })
    commit('assignDeleteMessage', result.data.message)
    commit('loadingDelete', false)
  },
  async getSurveyEditList ({ commit }, id) {
    const result = await Vue.axios?.get('get/survey?id=' + id)
    if (result?.data?.success !== true) return
    commit('assignSurveyEditList', result.data.data)
  },
  set_delete_popup: ({ state }, payload) => {
    state.delete_popup = payload
  },
  set_spatial_popup: ({ state }, title) => {
    state.edit_popup_open = false
    state.import_popup_open = false
    state.spatial_url_popup.open = true
    state.spatial_url_popup.title = title
    state.spatial_url_popup.description = 'กรุณาคัดลอกลิงก์ด้านล่าง ไปวางที่ "ข้อความยืนยัน" ของ Google Form'
  }
}

const mutations = {
  assignSurveyValue (state, value) {
    state.survey_spatial = value.data.data
  },
  assignSelectUserGroup (state, value) {
    state.survey_user_group = value
  },
  assignSurveyManagementList (state, value) {
    state.survey_management_list = value
    state.survey_management_list.forEach(item => {
      const date = new Date(item.created_date)
      const result = util.dateFormat(date)
      item.created_date = `${result} น.`
    })
  },
  assignDeleteMessage (state, value) {
    state.message_delete = value
    if (state.message_delete) Vue.$toast.success(state.message_delete)
  },
  assignSurveyEditList (state, value) {
    state.survey_edit_list = value
  },
  loadingDelete (state, value) {
    state.loading_delete = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
