import Vue from 'vue'

const state = {
  spatial_url: null,
  loading: false
}

const getters = {
}

const actions = {
  setSurvey: async ({ state, dispatch }, result) => {
    state.loading = true
    await Vue.axios.post('set/survey', JSON.stringify(result), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        state.loading = false
        if (response.data.success) {
          Vue.$toast.success(response.data.message)
          if (response.data.data.spatial_url !== null) {
            state.spatial_url = process.env.VUE_APP_BASE_PATH + response.data.data.spatial_url
            let title = ''
            if (result.id !== null) {
              title = `แก้ไขแบบสำรวจ${result.name}สำเร็จ`
            } else {
              title = `บันทึกแบบสำรวจ${result.name}สำเร็จ`
            }
            dispatch('surveyManagementList/getSurveyManagementList', null, { root: true }).then(() => {
              dispatch('surveyManagementList/set_spatial_popup', title, { root: true })
            })
          } else {
            Vue.$toast.error(response.data.message)
          }
        }
      })
      .catch((error) => {
        state.loading = false
        if (!error.response.data?.success) {
          Vue.$toast.error(error.response.data?.message ? error.response.data?.message : error.message)
        }
      })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
